import { Fragment } from "react";

export default function WelcomeText() {
    return (
        <Fragment>
            <h2>
                Hitta hit
            </h2>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8009.160650987203!2d17.647044!3d59.877532!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7f133160cc90ce80!2sSalong%20Herkules!5e0!3m2!1ssv!2sse!4v1629206241269!5m2!1ssv!2sse"
                width="100%"
                height={500}
                title="map"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"></iframe>
        </Fragment>
    )
}
