const IFRAME_SRC = "https://timma.se/reservation/salongherkules";

export default function Booking() {
    return (    
        <div style={{ display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      width: '100%'}}>
            <h2>
                Priser och tidsbokning
            </h2>
            <iframe
                title="book-service"
                src={IFRAME_SRC}
                width="inherit" frameborder="0" height={500}
                style={{ border: '2px 2px blue', width:"100%" }}>
            </iframe>
        </div>
    )
}
