import {
  Container,
  CssBaseline,
} from '@material-ui/core';
import { Fragment } from 'react';
import Booking from './Components/Content/Booking';
import HairLength from './Components/Content/HairLength';
import ProductsAndPrices from './Components/Content/ProductsAndPrices';
import Slideshow from './Components/Content/Slideshow';
import Praktikant from './Components/Content/Praktikant';
import WelcomeText from './Components/Content/WelcomeText';
import Head from './Components/Head';

function App() {
  return (
    <Fragment>
      <link
        href='https://fonts.googleapis.com/css?family=Gruppo'
        rel='stylesheet'>
      </link>
      <CssBaseline />

        <Container>
          <div
            style={{
              display: 'flex', flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              fontFamily: 'Gruppo',
              backgroundColor: "#f2f5fa"
            }}>
            <Head />
            <Slideshow />
            <Praktikant/>
            <HairLength/>
            <Booking/>
            <WelcomeText />
            <ProductsAndPrices />
            <div style={{
              height: 100, display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}>
              <h5>
                Copyright © {new Date().getFullYear()} hercules
              </h5>
            </div>
          </div>
        </Container>
    </Fragment>
  );
}

export default App;
