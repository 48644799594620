import MediaCard from './Gallery/Card';

import ms1 from './ProductImages/milkshake.JPG'
import ms2 from './ProductImages/milkshake2.JPEG'
import ms3 from './ProductImages/milkshake3.JPEG'
import wn from './ProductImages/whynot.PNG'
import wn2 from './ProductImages/whynot2.JPG'
import chicks from './ProductImages/chicks.JPEG'

const products = [
  {
    img: chicks,
    title: '',
    description: '',
    price: ""
  },
  {
    img: wn,
    title: '',
    description: '',
    price: ""
  },
  {
    img: wn2,
    title: '',
    description: '',
    price: ""
  },
  {
    img: ms1,
    title: '',
    description: '',
    price: ""
  },
  {
    img: ms2,
    title: '',
    description: '',
    price: ""
  },
  {
    img: ms3,
    title: '',
    description: '',
    price: ""
  },


];


export default function Products() {

  function generateCards() {
    const cards = [];
    for (const item of products) {
      cards.push(<MediaCard image={item.img}
        width={200}
        heigth={1000}
        title={item.title}
        description={item.description}
        price={item.price}
        key={item.title}
      />)
    }
    return cards;
  }

  return (
    <div style={{
      display: "flex", width: "100%", flexDirection: "column",
      alignItems: "center"
    }}>
      <h2>Produkter</h2>
      <div style={{
        display: "flex",
        flexDirection: "Row",
        justifyContent: "center",
        alignItems: "flex-start",
        flexWrap: "wrap",
        width: "100%"
      }}>
        {generateCards()}
      </div>
    </div>
  )
}
