import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 10
  },
  media: {
    height: 140,
  },
});

export default function MediaCard({image, title,
                                   description, price, width, height}) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={image}
          title={title}
          style={{width: width, height: height}}
        >
        </CardMedia>
        {/* <CardContent>
          <Typography gutterBottom variant="h5"
                      component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary"
                      component="p">
            {description}
          </Typography>
        </CardContent> */}
      </CardActionArea>
      {/* <CardActions>
        <Button size="small" color="primary">
          Pris: {price}
        </Button>
      </CardActions> */}
    </Card>
  );
}
