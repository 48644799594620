import React from 'react'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import first from './01.jpg'
import second from './02.JPG'
import third from './03.JPG'
import fourth from './04.JPG'

const images = [
  {
    original: first,
    originalHeight: 400
  },
  {
    original: second,
    originalHeight: 400
  },
  {
    original: third,
    originalHeight: 400

  },
  {
    original: fourth,
    originalHeight: 400
  },
];

export default function Slideshow() {
    return (
        <div style={{height: 400, width: "100%"}}>
             <ImageGallery items={images} 
                       showThumbnails={false} autoPlay={true} showPlayButton={false}/>
        </div>
    )
}
